import React, { useState, useEffect } from "react"
import { FooterStyled, SingleAccord, CardBodyStyled } from "./footerStyle"
import Logo from "../shared/logo/logo"
import LanguageContext from "../../context/LanguageContext"
import { Accordion, Card, Container, Col, Row } from "react-bootstrap"
import facebook from "../../images/icons/facebook.png"
import insta from "../../images/icons/instagram.png"
import img from "../../images/janowo-2/wiz-janowo-3-mini.jpg"
import imgPuck from "../../images/puck/z16-visual-footer.jpg"
import imgOJP3 from "@/images/z-17/Janowo_Park_III_01_V04_footer.jpg"
import wiczlino from "@/images/z-19/ogw2-mini.jpg"
import { injectIntl, IntlContextConsumer, Link } from "gatsby-plugin-intl"
import locationimg from "../../images/location.png"
import logo from "../../images/logoadgroup.png"
import { SingleLang } from "../navbar/languageDropdown"
import { jobOffersCount } from "@/data/jobOffers"

const Footer = ({ intl }) => {
  const [activeTab, setActiveTab] = useState(false)
  const [currentYear, setCurrentYear] = useState("")

  const year = new Date().getFullYear()
  useEffect(() => {
    setCurrentYear(year)
  }, [])

  const Company = () => (
    <Row>
      <Col xs={12}>
        <div className="subtitle no-hours">Willbud Gdynia</div>
      </Col>
      <Col xs={5}>
        <p>{intl.formatMessage({ id: "headquater.adress.street" })}</p>
        <p>
          {intl.formatMessage({ id: "headquater.adress.post" })}
          <a
            href="https://goo.gl/maps/8vR5dCe1DxNsKhPm8"
            target="_blank"
            rel="noopener"
            className="address-icon"
          >
            <img src={locationimg} alt="ikona" />
          </a>
        </p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.mail" })}
        </p>
      </Col>
      <Col xs={7}>
        <p>{intl.formatMessage({ id: "sales.headquater.adress.hours" })}</p>
        <p className={"last-contact-paragraph"}>
          {intl.formatMessage({ id: "office.adress.hours.weekend" })}
        </p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.tel" })}
        </p>
      </Col>
    </Row>
  )

  const CompanyPuck = () => (
    <Row>
      <Col xs={12}>
        <div className="subtitle no-hours">WILLBUD Puck</div>
      </Col>
      <Col xs={5}>
        <p>ul. Wejherowska 43</p>
        <p>
          84-100 Puck
          <a
            href="https://goo.gl/maps/8vR5dCe1DxNsKhPm8"
            target="_blank"
            rel="noopener"
            className="address-icon"
          >
            <img src={locationimg} alt="ikona" />
          </a>
        </p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.mail" })}
        </p>
      </Col>
      <Col xs={7}>
        <p>Pn. - Pt. 10:00 - 18:00</p>
        <p className={"last-contact-paragraph"}>
          {intl.formatMessage({ id: "office.adress.hours.weekend" })}
        </p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.tel" })}
        </p>
      </Col>
    </Row>
  )

  const Headquater = () => (
    <>
      <div className="subtitle">Willbud Gdynia</div>
      <p>{intl.formatMessage({ id: "headquater.adress.street" })}</p>
      <p>{intl.formatMessage({ id: "headquater.adress.post" })}</p>
      {/* <p>{intl.formatMessage({ id: "headquater.adress.hours" })}</p> */}
      <p className={"mb-4"}>{intl.formatMessage({ id: "office.tel" })}</p>
    </>
  )

  const CompanySale = () => (
    <Row>
      <Col xs={12}>
        <div className="subtitle no-hours">WILLBUD Rumia</div>
      </Col>
      <Col xs={5}>
        <p>{intl.formatMessage({ id: "office.adress.street" })}</p>
        <p>
          {intl.formatMessage({ id: "office.adress.post" })}
          <a
            href="https://goo.gl/maps/1LmbGgb4rt62yCZp9"
            target="_blank"
            rel="noopener"
            className="address-icon"
          >
            <img src={locationimg} alt="ikona" />
          </a>
        </p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.mail" })}
        </p>
      </Col>
      <Col xs={7}>
        <p>Pn. - Pt. 08:00 - 19:00</p>
        <p className={"last-contact-paragraph"}>Sb. - 08:00 - 15:00</p>
        <p className={"d-none d-lg-flex"}>
          {intl.formatMessage({ id: "office.tel" })}
        </p>
      </Col>
    </Row>
  )

  const RegisterData = () => (
    <>
      <div className="subtitle">WILLBUD</div>
      <p>{intl.formatMessage({ id: "headquater.adress.street" })}</p>
      <p>{intl.formatMessage({ id: "headquater.adress.post" })}</p>
      <p>{intl.formatMessage({ id: "company.adress.krs" })}</p>
      <p>{intl.formatMessage({ id: "company.adress.nip" })}</p>
      <p>{intl.formatMessage({ id: "company.adress.regon" })}</p>
    </>
  )

  const ContactInfo = () => (
    <>
      <div className="subtitle">Kontakt</div>
      <p>{intl.formatMessage({ id: "office.mail" })}</p>
      <p>{intl.formatMessage({ id: "office.tel" })}</p>
    </>
  )
  const FooterNav = () => (
    <div className={"footer-mobile-nav"}>
      <Link to={"/oferta"}>
        <div className={"nav-link"}>
          {intl.formatMessage({ id: "nav.investments" })}
        </div>
      </Link>

      {/*<Link to={"/uslugi"}>
        <div className={"nav-link"}>
          {intl.formatMessage({ id: "nav.services" })}
        </div>
      </Link>*/}
      <Link to={"/firma"}>
        <div className={"nav-link"}>
          {intl.formatMessage({ id: "nav.company" })}
        </div>
      </Link>
      <Link to={"/kariera"}>
        <div className={"nav-link"}>
          {intl.formatMessage({ id: "nav.dropdown.company.career" })}{" "}
          <small style={{ color: "#94C835" }}>({jobOffersCount})</small>
        </div>
      </Link>
      <Link to={"/kontakt"}>
        <div className={"nav-link"}>
          {intl.formatMessage({ id: "nav.contact" })}
        </div>
      </Link>
    </div>
  )
  const SocialMedia = () => (
    <div className="icons">
      <a
        href={"https://www.facebook.com/WillbudDeweloper/"}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={facebook} alt="" />
      </a>
      <a
        href={"https://www.instagram.com/willbuddeweloper/"}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={insta} alt="" />
      </a>
    </div>
  )

  return (
    <FooterStyled>
      <div className="custom-container">
        <div className="flex-center">
          <div className="mobile-logo d-xl-none">
            <Logo theme={"light"} />
          </div>
          <div className={"mobile-nav d-xl-none"}>
            <Accordion defaultActiveKey={false}>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <SingleAccord
                  onClick={() => setActiveTab(activeTab === 0 ? false : 0)}
                  active={activeTab === 0}
                >
                  {intl.formatMessage({ id: "footer.menu.title" })}
                </SingleAccord>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <CardBodyStyled>
                  <Card.Body>
                    <FooterNav />
                  </Card.Body>
                </CardBodyStyled>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <SingleAccord
                  onClick={() => setActiveTab(activeTab === 1 ? false : 1)}
                  active={activeTab === 1}
                >
                  {intl.formatMessage({ id: "company.title" })}
                </SingleAccord>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <CardBodyStyled>
                  <Card.Body>
                    <RegisterData />
                  </Card.Body>
                </CardBodyStyled>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <SingleAccord
                  onClick={() => setActiveTab(activeTab === 3 ? false : 3)}
                  active={activeTab === 3}
                >
                  {intl.formatMessage({ id: "office.title" })}
                </SingleAccord>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <CardBodyStyled>
                  <Card.Body>
                    <CompanySale />
                    <Company />
                    <ContactInfo />
                  </Card.Body>
                </CardBodyStyled>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <SingleAccord
                  onClick={() => setActiveTab(activeTab === 4 ? false : 4)}
                  active={activeTab === 4}
                >
                  {intl.formatMessage({ id: "headquater.title" })}
                </SingleAccord>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <CardBodyStyled>
                  <Card.Body>
                    <Headquater />
                  </Card.Body>
                </CardBodyStyled>
              </Accordion.Collapse>
            </Accordion>
            <div className="social-media">
              <div className="language">
                <LanguageContext.Consumer>
                  {language => (
                    <div className="list-lang">
                      <IntlContextConsumer>
                        {({ languages }) => (
                          <>
                            {console.log(languages)}
                            {languages.map((lang, i) => (
                              <SingleLang
                                mobileView={true}
                                language={language}
                                selectLanguage={lang}
                                key={i}
                              />
                            ))}
                          </>
                        )}
                      </IntlContextConsumer>
                    </div>
                  )}
                </LanguageContext.Consumer>
              </div>
              <SocialMedia />
            </div>
            <div className="copyrights">
              <p>{intl.formatMessage({ id: "footer.copyrights" })}</p>
              <p className={"copyrights--date ml-1"}>
                © Willbud {currentYear && currentYear}
              </p>
            </div>
            <div className="nav">
              <Link to={"/polityka-prywatnosci"}>
                {intl.formatMessage({ id: "nav.privacy" })}
              </Link>

              <Link to={"/dokumenty-prawne"}>
                {/*{intl.formatMessage({ id: "nav.privacy" })}*/}
                Dokumenty Prawne
              </Link>
            </div>
          </div>
          <Container>
            <div className="desktop-container d-none d-lg-flex">
              <div className="register-data-left">
                <div className="logo">
                  <Logo theme={"light"} />
                </div>
                <div className="column-styled">
                  <RegisterData />
                </div>
              </div>
              <div className="container-data-right">
                <div className="footer-desktop-nav column-styled col-lg-3 col-xl-1">
                  <div className="title">
                    {intl.formatMessage({
                      id: "footer.menu.title",
                    })}
                  </div>
                  <FooterNav />
                  <div className="social-media">
                    <SocialMedia />
                  </div>
                </div>
                <div className="investments column-styled col-5">
                  <div className="title">
                    {intl.formatMessage({ id: "footer.lastProjects.title" })}
                  </div>
                  <Link to={"/oferta/osiedle-janowo-park-3"}>
                    <div className="single-investment">
                      <img
                        src={imgOJP3}
                        alt="OJP3"
                        style={{ width: "100%", maxWidth: "132px" }}
                      />
                      <div className="content">
                        <div>
                          <div className="name">Osiedle Janowo Park III</div>
                          <div className="button-flex">
                            <div className="location">
                              <div className="icon">
                                <img
                                  src={locationimg}
                                  alt="Osiedle Janowo Park III"
                                />
                              </div>
                              <div className="name">
                                {intl.formatMessage({
                                  id: "estate.city.rumia",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/oferta/osiedle-pogodne-w-pucku"}>
                    <div className="single-investment">
                      <img
                        src={imgPuck}
                        alt=""
                        style={{ width: "100%", maxWidth: "132px" }}
                      />
                      <div className="content">
                        <div>
                          <div className="name">
                            {intl.formatMessage({
                              id: "estate.name.puck",
                            })}
                          </div>
                          <div className="button-flex">
                            <div className="location">
                              <div className="icon">
                                <img
                                  src={locationimg}
                                  alt={intl.formatMessage({
                                    id: "estate.name.puck",
                                  })}
                                />
                              </div>
                              <div className="name">
                                {intl.formatMessage({
                                  id: "estate.city.puck",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/oferta/osiedle-gdynia-wiczlino-2"}>
                    <div className="single-investment">
                      <img src={wiczlino} alt="" />
                      <div className="content">
                        <div>
                          <div className="name">
                            {intl.formatMessage({
                              id: "estate.name.wiczlinotwo",
                            })}
                          </div>
                          <div className="button-flex">
                            <div className="location">
                              <div className="icon">
                                <img src={locationimg} alt="" />
                              </div>
                              <div className="name">
                                {intl.formatMessage({
                                  id: "estate.city.gdynia",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/oferta/osiedle-janowo-park-2"}>
                    <div className="single-investment">
                      <img src={img} alt="" />
                      <div className="content">
                        <div>
                          <div className="name">
                            {intl.formatMessage({
                              id: "estate.name.janowoParkTwo",
                            })}
                          </div>
                          <div className="button-flex">
                            <div className="location">
                              <div className="icon">
                                <img
                                  src={locationimg}
                                  alt={intl.formatMessage({
                                    id: "estate.name.janowoParkTwo",
                                  })}
                                />
                              </div>
                              <div className="name">
                                {intl.formatMessage({
                                  id: "estate.city.rumia",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="company-sale column-styled col-lg-9 col-xl-6 d-flex align-items-start justify-content-start flex-column">
                  <div className="title">
                    <Col>{intl.formatMessage({ id: "office.title" })}</Col>
                  </div>
                  <CompanySale />
                  <Company />
                  <CompanyPuck />
                </div>
              </div>
            </div>
            <div className="copyrights d-none d-lg-flex">
              <p>
                {intl.formatMessage({ id: "footer.copyrights" })} © Willbud{" "}
                {currentYear && currentYear}
              </p>
              <p className={"ml-auto"}>
                <Link to={"/polityka-prywatnosci"}>
                  {intl.formatMessage({ id: "nav.privacy" })}
                </Link>
                <Link to={"/dokumenty-prawne"}>
                  {/*{intl.formatMessage({ id: "nav.privacy" })}*/}
                  Dokumenty Prawne
                </Link>
              </p>
            </div>
          </Container>
        </div>
      </div>
    </FooterStyled>
  )
}

export default injectIntl(Footer)
